import { TonConnectUIProvider } from "@tonconnect/ui-react";
import React, { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
import AppRouter from "./components/AppRouter";
import { Providers } from "./components/providers";
import { $auth } from "./config/api.config";
import { useTelegram } from "./hooks/useTelegram.hook";
import { Context } from "./index";
import "./main.scss";

function App() {
    const allowedPaths = ["/admin", "/login"];
    if(!window.Telegram.WebApp.initDataUnsafe?.user?.id &&
         !allowedPaths.some((path) => window.location.pathname.startsWith(path))){
        window.location.href = "https://t.me/nvuttibot";
    }


    const {webApp} = useTelegram()
    webApp.expand()
    
    const {admin} = useContext(Context);
    useEffect(() => {
        const auth = async() =>{
            admin.setLoading(true)
            try {
                const {data} = await $auth.post("/admin/refresh")
                if(data){
                    admin.setIsAuth(true)
                }
            }catch (e) {
                console.log(e)
            }
            admin.setLoading(false)
        }
        auth()
    });

    return (
        <div className="App">
            <Toaster position="top-right" theme={"dark"} richColors={true}/>
            <TonConnectUIProvider manifestUrl="https://nvutti.tech/tonconnect-manifest.json">
                <div className="wrapper">
                    <div className="main_container">
                        <Providers>
                            <BrowserRouter>
                                <AppRouter/>
                            </BrowserRouter>
                        </Providers>
                    </div>
                </div>
            </TonConnectUIProvider>
        </div>
    );
}

export default App;
